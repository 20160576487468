<!-- 查看详情 -->
<template>
  <el-dialog
    title="查看详情"
    :visible.sync="detailShow"
    width="50%"
    @click="closeDialog"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <div style="padding-left: 4%">
      <a-descriptions title="" layout="vertical">
        <a-descriptions-item label="活动名称">
          <div class="item">xxx</div></a-descriptions-item
        >
        <a-descriptions-item label="活动类型">xxx</a-descriptions-item>
        <a-descriptions-item label="报名时间"></a-descriptions-item>
        <a-descriptions-item label="举办地点">xx </a-descriptions-item>
        <a-descriptions-item label="举办时间">xx </a-descriptions-item>
        <a-descriptions-item label="举办单位">xx</a-descriptions-item>
        <a-descriptions-item label="限制人数">xx</a-descriptions-item>
        <a-descriptions-item label="所属网格">xx </a-descriptions-item>
        <a-descriptions-item label="活动内容">xx</a-descriptions-item>
        <a-descriptions-item label="获奖名额" span="3">
          <a-descriptions layout="vertical">
            <a-descriptions-item label="1等奖">xxx</a-descriptions-item>
            <a-descriptions-item label="1等奖奖品" span="2"
              >xxx</a-descriptions-item
            >
            <a-descriptions-item label="2等奖">xxx</a-descriptions-item>
            <a-descriptions-item label="2等奖奖品" span="2"
              >xx
            </a-descriptions-item>
            <a-descriptions-item label="3等奖">xxx</a-descriptions-item>
            <a-descriptions-item label="3等奖奖品" span="2"
              >xx
            </a-descriptions-item>
          </a-descriptions>
        </a-descriptions-item>
        <a-descriptions-item label="活动状态">xx </a-descriptions-item>
        <a-descriptions-item label="创建人">xx</a-descriptions-item>
        <a-descriptions-item label="创建时间">xx</a-descriptions-item>
      </a-descriptions>
      <div style="display: flex; justify-content: flex-end">
        <a-button type="primary" @click="onSubmit"> 保存 </a-button>
        <a-button style="margin-left: 20px" @click="closeDialog">
          取消
        </a-button>
      </div>
    </div>
  </el-dialog>
</template>
  <script>
import api from "./api";

export default {
  components: {},
  data() {
    return {
      api,
      id: "",
      detailShow: false,
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.detailShow = true;
      this.id = record.id;
      console.log(this.id);
      this.$nextTick(() => {
        this.getData();
      });
    },
    //查看
    async getData() {
      const res = await api.getById(this.id);
      console.log(res.data);
    },
    //提交
    async onSubmit() {
      // 提交成功后关闭弹窗
      this.refreshTable();
      this.closeDialog();
    },
    //关闭弹窗
    closeDialog() {
      this.detailShow = false;
    },
    //刷新代码
    refreshTable() {
      this.$emit("refreshTable");
      console.log(11111);
    },
  },
};
</script>
      
  <style lang="less" scoped>
/deep/.ant-modal {
  top: 0;
}
/deep/.ant-descriptions-item-content {
  display: table-cell;
  font-weight: 400;
  font-size: 14px;
  color: #2d3138;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  text-transform: none;
}
/deep/.ant-descriptions-item-label {
  font-weight: 400;
  font-size: 14px;
  color: #7d8da6;
  line-height: 18px;
  text-align: left;
}
</style>
      